import * as React from 'react';


function FollowMarker(params) {
  
  function render() {
    return (
      <div class="follow-marker">
        <h1>{ params.marker.description}</h1>

        <div class="region-text">
          { params.marker.region}
        </div>

        <div class="iframe-container" >
          <iframe src={ params.marker.glympseTrackingUrl } width="100%" height="100%" style={{overflow: 'none'}}></iframe>
        </div>
      </div>
    )
  }

  
  return render();
}

export default FollowMarker;
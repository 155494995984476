import Menu from "./Menu";
import RouteController from "./RouteController";

function Layout() {
  let appBodyCss = "app-body";

  if (window.location.search.indexOf("fullscreen=true") > 0) {
    // Check for the fullscreen URL param to override the maxwidth CSS setting
    appBodyCss = `${appBodyCss}-fullscreen`;
  }

  function render() {
    return (
      <div id="layout">
        <Menu />        
        
        <div id={appBodyCss}>
          <RouteController />
        </div>
      </div>
    );
  };

  return render();
}

export default Layout;
import { useEffect, useState } from "react";
import ServiceAPI from '../service/ServiceAPI';
import Button from '@mui/material/Button';
import Constants from "../utils/Constants";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function Event() {

  const [noEventsToShow, setNoEventsToShow] = useState(false)
  const [eventListing, setEventListing] = useState(null);

  useEffect(() => {
    ServiceAPI.Events().getEventListing().then(
      (data) => { setEventListing(data); }
    ).catch(
      () => setNoEventsToShow(true)
    );
  }, []);


  function render() {
    if (noEventsToShow) {
      return <h1>There are no upcoming events at the moment.</h1>
    }

    if (eventListing == null) {
      return <h1>Event details are loading</h1>
    }

    let jsx = (
      <div>
        <h1>Select your event.</h1>

        <div>{ 
          eventListing.map((i) => {
            return renderCard(i);
          })
        }</div>
      </div>
    );

    return jsx;
   
  }

  function formatDateTime(timeStamp) {
    let date = new Date(timeStamp.replace(' ', 'T') + "+11:00"); // Convert to UTC
    
    // Convert to AEST
    let aestTime = new Date(date.toLocaleString('en-US', {timeZone: 'Australia/Sydney'}));
    
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    let formattedDate = aestTime.toLocaleDateString('en-US', options);
    
    return formattedDate;
  }

  function renderCard(event) {
    return (
      <Card variant="outlined" sx={{mb: '1em'}} key={event}>
        <>
          <CardContent>
            <Typography variant="h5" component="div" sx={{fontWeight: "bold"}}>
              { event.name }
            </Typography>
            <Typography sx={{ fontSize: 14}} color="text.secondary" gutterBottom>
              <div style={{ marginTop: ".5em" }}>
                tracking is live from
              </div>
              <Typography color="black">
                <div style={{ marginTop: "2em" }}>
                  { formatDateTime(event.startTime) }
                </div>
                until 
                <div>
                  { formatDateTime(event.endTime) }
                </div>
              </Typography>
            </Typography>
          </CardContent>
          <CardActions>
            {
              event.eventActive 
              ? <Button sx={{"flexGrow": 2}} variant="contained" size="large" href={Constants.RoutePath.Join(event.eventId)}>Select this Event</Button>
              : null
            }
          </CardActions>
        </>
      </Card>
    );
  }

  return render();
}

export default Event;